import { Button } from 'homebox-ui';
import React from 'react';
import { MdArrowDropUp } from 'react-icons/md';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from 'homebox-ui';
import { useFormikContext } from 'formik';
import { calculatePrice } from '../../utils/functions';
import { QuotePanelContent } from '../QuotePanel/QuotePanelContent';
// import { useLocation } from 'react-router-dom';
// import { AccountSummaryContent } from '../AccountSummaryPanel';
import CouponInput from '../CouponInput';
import { useCalculatePricePerHousemate } from '../../hooks/useCalculatePricePerHousemate';
import { useContainerSize } from '../../hooks/useContainerResize';
import ManageHouseHoldModal from '../ManageHouseholdModal';

export default function MobileNavControl({
  serviceBill,
  rate,
  setRate,
  children,
  coupon,
  setCoupon,
  couponApplied,
  setCouponApplied,
  setFetchQuote,
}) {
  const containerSize = useContainerSize();
  const lgScreen = containerSize.width >= 1024;

  const { values } = useFormikContext();
  const quoteData = values?.billSelected ?? [];
  const noOfBillPayers = values?.bill_payers;
  const noOfHousemates = values?.total_occupants;
  const isMonthly = rate === 'monthly';

  const { pricePerHousemate } = useCalculatePricePerHousemate(
    quoteData,
    serviceBill,
    noOfHousemates,
    coupon,
    noOfBillPayers
  );

  return (
    <Dialog>
      <DialogContent className='@sm:bottom-auto @sm:h-auto bottom-4 h-[85vh] max-w-xl overflow-y-scroll bg-transparent p-3 shadow-transparent sm:bg-white'>
        <div className='@sm:px-0 @sm:py-0 rounded-xl bg-white px-2 py-6 '>
          <DialogHeader>
            <DialogTitle className='h-10 text-center'>Summary</DialogTitle>
            <DialogDescription className='@lg:max-h-[75vh] @lg:p-6 flex max-h-screen flex-col space-y-2 overflow-y-scroll p-2'>
              <QuotePanelContent
                serviceBill={serviceBill}
                setRate={setRate}
                rate={rate}
                step={4}
                coupon={coupon}
                setCoupon={setCoupon}
                couponApplied={couponApplied}
                setCouponApplied={setCouponApplied}
                setFetchQuote={setFetchQuote}
              />
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className='mt-6'>
            <DialogTrigger asChild>
              <Button>Close</Button>
            </DialogTrigger>
          </DialogFooter>
        </div>
      </DialogContent>
      {!lgScreen && (
        <div className='shadow-lgUp @lg:hidden fixed bottom-0 left-0 flex min-h-[140px] w-full rounded-t-xl bg-white'>
          <div className='absolute -top-6 left-[50%] -translate-x-[50%] rounded-full bg-white p-2'>
            <DialogTrigger asChild>
              <Button variant={'secondary'} size={'icon'}>
                <MdArrowDropUp className='h-6 w-6' />
              </Button>
            </DialogTrigger>
          </div>
          <div className=' w-full px-6 pb-12 pt-4'>
            <div className='flex min-h-[3rem] justify-between'>
              <div className='@lg:max-w-[200px] flex-col justify-end space-y-2 text-base'>
                <p className='font-semibold '>Total</p>
                <div className='w-full'>
                  <CouponInput
                    coupon={coupon}
                    setCoupon={setCoupon}
                    couponApplied={couponApplied}
                    setCouponApplied={setCouponApplied}
                  />
                </div>
              </div>
              <div className='flex flex-col justify-start space-y-2 '>
                <p className='text-base'>
                  <span className='text-typography font-medium'>
                    £
                    {calculatePrice(
                      Number(pricePerHousemate),
                      noOfHousemates,
                      noOfBillPayers,
                      isMonthly
                    )}
                  </span>
                  {isMonthly ? '/month' : '/week'}
                </p>
                {noOfBillPayers > 1 && (
                  <p className='text-sm font-normal'>per bill payer</p>
                )}
                <ManageHouseHoldModal setFetchQuote={setFetchQuote} />
              </div>
            </div>
            <div className='mt-3 w-full'>
              {/* <Button className='w-full'>Continue</Button> */}
              {children}
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}
