import React, { useEffect, useRef, useState } from 'react';
import OnboardingLayout from '../layouts/OnboardingLayout';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useGlobalConfig } from '../context';
import {
  useCreatePartnerOrderMutation,
  useAddBillPayerToTenantOrderMutation,
  useGetQuoteLeadQuery,
  useGetTenantPackageQuery,
  useUpdateHouseholdMutation,
} from '../app/services';
import { dateFromStartDate, invalidDomains } from '../utils';
import { useExtractQuoteBills } from '../hooks/useExtractQuoteBills';
import useLoadPartner from '../hooks/useLoadPartner';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

export default function CustomerOnboarding() {
  const navigate = useNavigate();
  const { config } = useGlobalConfig();
  const [loadPartner, setLoadPartner] = useState(!config?.widgetMode);
  const { partner, nameUrl, partnerError } = useLoadPartner(true);

  const [rate, setRate] = React.useState('monthly');
  const userPostCode = config?.userParams?.address?.postcode;
  const userIsStudent = config?.userParams?.is_student;

  let threeMonthsFromToday = dateFromStartDate(new Date(), 3);

  let [searchParams] = useSearchParams();

  const [IS_TEST_ENV] = useState(searchParams.get('test') === '1');
  const quoteUUID = searchParams.get('quote');

  let [postCode, setPostCode] = useState(userPostCode || '');
  const [propertyId, setPropertyId] = useState(null);
  let [addressId, setAddressId] = useState(null);
  let [manualAddress, setManualAddress] = useState({});

  let [housemates, setHousemates] = useState(1);
  let [bill_payers, setBillPayers] = useState(1);
  let [isStudent, setIsStudent] = useState(false);
  let [coupon, setCoupon] = useState('');
  let [couponApplied, setCouponApplied] = useState(false);

  const [minEndDate, setMinEndDate] = useState(threeMonthsFromToday);
  const [currentStep, setCurrentStep] = useState(0);

  let formikRef = useRef(null);
  const [fetchQuote, setFetchQuote] = useState(null);
  const [quoteLead, setQuoteLead] = useState({});

  useEffect(() => {
    if (formikRef?.values && Object.entries(formikRef.values).length > 0) {
      const externalId = formikRef?.values?.address?.external_id;
      if (externalId) {
        setAddressId(externalId);
      }

      const propertyId = formikRef?.values?.property_id;
      if (propertyId) {
        setPropertyId(propertyId);
      }

      setHousemates(formikRef?.values?.total_occupants);
      let householdType = formikRef?.values?.household_type === 'student';
      setIsStudent(householdType || userIsStudent);
      setBillPayers(formikRef?.values?.bill_payers);
    }
  }, [formikRef, fetchQuote, userIsStudent]);

  const currentPartnerToken = useSelector(
    state => state.partnerAuth.partnerToken
  );
  const { data: quoteLeadResponse, error: quoteLeadError } =
    useGetQuoteLeadQuery(
      {
        uuid: quoteUUID,
        partnerNameUrl: nameUrl,
      },
      { skip: !(quoteUUID && nameUrl && currentPartnerToken) }
    );

  useEffect(() => {
    if (quoteLeadResponse && quoteLeadResponse?.data) {
      setQuoteLead(quoteLeadResponse.data);
      const { property, housemates, is_student } = quoteLeadResponse.data;
      setPostCode(property?.address_postcode);
      setHousemates(housemates);
      setIsStudent(is_student);
      setAddressId(property?.external_id);
      setFetchQuote(true);
    }
  }, [quoteLeadResponse]);

  const {
    data: customPackageData,
    isLoading,
    isSuccess: packageDataIsSuccess,
    error: packageError,
  } = useGetTenantPackageQuery(
    {
      ...(propertyId
        ? { property_id: propertyId }
        : {
            address_id:
              quoteLeadResponse?.data?.property?.external_id || addressId,
          }),
      line1:
        quoteLeadResponse?.data?.property?.address_line1 ||
        manualAddress?.line1,
      city:
        quoteLeadResponse?.data?.property?.address_city || manualAddress?.city,
      postcode: quoteLeadResponse?.data?.property?.address_postcode || postCode,
      housemates: quoteLeadResponse?.data?.housemates || housemates,
      bill_payers: quoteLeadResponse?.data?.bill_payers || bill_payers,
      is_student: isStudent,
    },
    {
      skip: quoteLeadResponse
        ? false
        : !(
            postCode &&
            housemates &&
            fetchQuote &&
            partner &&
            (addressId || manualAddress?.line1 || propertyId)
          ),
    }
  );

  const { essentials, extras, perks, serviceBill, isExtractBillSuccess } =
    useExtractQuoteBills(customPackageData);

  const [
    createOrder,
    // eslint-disable-next-line no-unused-vars
    { error: orderError },
  ] = useCreatePartnerOrderMutation();

  const [orderId, setOrderId] = useState(null);

  const [
    addBillPayerToOrder,
    // eslint-disable-next-line no-unused-vars
    { data: userData, isSuccess: addedUser },
  ] = useAddBillPayerToTenantOrderMutation();

  const [isUserInputValidated, setIsUserInputValidated] = useState(false);

  const userId = userData?.data?.id;

  const layoutData = {
    serviceBill,
    setRate,
    rate,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    setHousemates,
    setAddressId,
    setFetchQuote,
    setIsStudent,
    setPostCode,
    setCurrentStep,
    fetchingQuote: isLoading,
    setLoadPartner,
    loadPartner,
    widgetMode: config?.widgetMode,
  };

  let maxDob = new Date();
  maxDob = new Date(maxDob.setFullYear(maxDob.getFullYear() - 18)).setMonth(12);

  const tenantOrderSchema = Yup.object().shape({
    household_type: Yup.string().required('Household type is required.'),
    address: Yup.object().shape({
      line1: Yup.string().required('Address Line 1 is required'),
      line2: Yup.string(),
      city: Yup.string().required('City is required.'),
      postcode: Yup.string().required('Postcode is required.'),
    }),
    total_occupants: Yup.number().required('Number of tenants is required.'),
    bill_payers: Yup.number()
      .required('Number of bill payers is required.')
      .test(
        'must-be-less-or-equal-to-occupants',
        'Housemates paying bills must be less than or equal to people living in the home',
        (value, context) => value <= context.parent.total_occupants
      ),
  });

  const accountSetupSchema = Yup.object().shape({
    email: Yup.string().email().required('email is required'),
    first_name: Yup.string().required('first name is required'),
    last_name: Yup.string().required('last name is required'),
    terms_of_service: Yup.boolean()
      .required('The terms of service must be accepted.')
      .oneOf([true], 'The terms of service must be accepted.'),
    birthday: Yup.date()
      .nullable()
      .typeError('Invalid birthday Date')
      .max(new Date(maxDob), 'you have to be older than 18.')
      .required('birthday is required.'),
    phone_number: Yup.string().required('phone number is required'),
    tenancy_start: Yup.date().required('The tenancy start date is required.'),
    tenancy_length: Yup.string().required('The tenancy duration is required.'),
    tenancy_end: Yup.date()
      .min(
        minEndDate,
        `We only take on customers who are in the property for a minimum of 6 months.`
      )
      .required('The tenancy end date is required.'),
  });

  const householdDetailsSchema = Yup.object().shape({
    university_name: Yup.string(),
    university_team: Yup.string(),
    letting_agent: Yup.object().shape({
      type: Yup.string().required('Letting agent type is required'),
      name: Yup.string().when('type', (type, schema) => {
        if (type[0] !== 'other') {
          return schema.required('Letting agent name is required');
        }

        return schema;
      }),
      email: Yup.string()
        .nullable()
        .email('Please enter a valid email address')
        .test('domain', 'Please use a real email address', value => {
          if (!value) return true;

          const emailDomain = value.split('@')[1];
          return !invalidDomains.includes(emailDomain);
        }),
      phone_number: Yup.string().nullable(),
    }),
  });

  const getValidationSchema = () => {
    switch (currentStep) {
      case 1:
        return tenantOrderSchema;
      case 3:
        return accountSetupSchema;
      case 6:
        return householdDetailsSchema;
      default:
        return null;
    }
  };

  const getSubmitForm = () => {
    return currentStep === 6 ? householdDetailsForm : orderForm;
  };

  const orderForm = async (values, setErrors) => {
    setErrors({});

    try {
      let userPayload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
        birthday: format(values.birthday, 'dd/MM/yyyy'),
        nationality: values.nationality,
        welcome_signup_email: values.welcome_signup_email,
        university_year: values.university_year,
      };

      const { error: validationError } = await addBillPayerToOrder({
        ...userPayload,
        precognition: true,
      });

      if (validationError?.data?.errors) {
        const errors = validationError.data.errors;

        Object.keys(errors).forEach(key => {
          setErrors({ [key]: errors[key] });
        });

        return;
      }

      setIsUserInputValidated(true);

      const add_ons =
        values?.billSelected.length > 0
          ? values?.billSelected.map(x => x?.id)
          : [];

      const tenancy_start = format(values.tenancy_start, 'dd/MM/yy');
      const tenancy_end = format(values.tenancy_end, 'dd/MM/yy');
      const tenancy_move_in = values.move_in_start
        ? tenancy_start
        : format(values?.tenancy_move_in, 'dd/MM/yy');

      const perks =
        values?.perks?.length > 0 ? values?.perks.map(x => x?.id) : [];

      let order_id = orderId;

      if (!order_id || userId) {
        const order = await createOrder({
          ...values,
          package_id: customPackageData.id,
          address: {
            line1: values?.address?.line1,
            line2: values?.address?.line2,
            city: values?.address?.city,
            postcode: values?.address?.postcode || postCode,
            ...(!propertyId &&
              values?.address.external_id && {
                address_id: values?.address?.external_id,
              }),
          },
          ...(propertyId && { property_id: propertyId }),
          add_ons,
          tenancy_move_in,
          tenancy_start,
          tenancy_end,
          coupon: coupon?.code,
          perks,
        });

        order_id = order.data?.data?.order_id;
        setOrderId(order_id);
      }

      if (order_id) {
        const { error } = await addBillPayerToOrder({
          ...userPayload,
          orderId: order_id,
        });

        if (error?.data?.errors) {
          const errors = error.data.errors;

          Object.keys(errors).forEach(key => {
            setErrors({ [key]: errors[key] });
          });
        }
      } else {
        toast.error('Failed to add the user to the order. Please try again.');
      }
    } catch (err) {
      const message = err?.message || 'Something went wrong, try again.';
      toast.error(`${message}`);
    }
  };

  const [
    updateHousehold,
    // eslint-disable-next-line no-unused-vars
    { isSuccess: updatedHousehold },
  ] = useUpdateHouseholdMutation();

  const householdDetailsForm = async (values, setErrors) => {
    setErrors({});

    let payload = {
      orderId: orderId,
      university_name: values.university_name,
      university_team: values.university_team,
    };

    payload.letting_agent =
      values.letting_agent.type !== 'other'
        ? {
            type: values.letting_agent.type,
            name: values.letting_agent.name,
            email: values.letting_agent.email,
            phone_number: values.letting_agent.phone_number,
          }
        : {
            type: values.letting_agent.type,
          };

    try {
      const { error } = await updateHousehold(payload);

      if (error?.data?.errors) {
        const errors = error.data.errors;
        Object.keys(errors).forEach(key => {
          if (!key.includes('.')) {
            setErrors({ ...errors, [key]: errors[key] });
            return;
          }

          const [parent, child] = key.split('.');
          setErrors({ ...errors, [parent]: { [child]: errors[key][0] } });
        });

        return;
      }
    } catch (err) {
      toast.error('Something went wrong, try again');
    }
  };

  useEffect(() => {
    if (orderError) {
      const message =
        orderError?.message || 'Failed to create the order, please try again.';

      toast.error(`${message}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderError]);

  useEffect(() => {
    if (orderId && addedUser && isUserInputValidated) {
      navigate(`/${nameUrl}/bill-payers`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedUser, isUserInputValidated]);

  useEffect(() => {
    let message = packageError?.message || 'Something went wrong, try again';
    const error = packageError || quoteLeadError;

    if (quoteLeadError) {
      message =
        quoteLeadError?.message ||
        'Sorry we could not find your quote, please try again';
    }
    if (error) {
      toast.error(`${message}`);
      const timer = setTimeout(() => {
        navigate(`/${nameUrl}/setup-account`);
        navigate(0);
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageError, quoteLeadError]);

  useEffect(() => {
    if (partnerError && nameUrl !== 'homebox') {
      navigate(`/${config?.partnerId || 'homebox'}/setup-account`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerError, nameUrl, config?.partnerId]);

  const sharedContext = {
    extras,
    postCode,
    setPostCode,
    essentials,
    orderId,
    userId,
    setFetchQuote,
    fetchingQuote: isLoading,
    quoteDataLoading: isLoading,
    quoteDataError: packageError,
    quoteDataIsSuccess: packageDataIsSuccess,
    packageDataIsSuccess,
    isExtractBillSuccess,
    isLoading,
    minEndDate,
    setMinEndDate,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    perks,
    setRate,
    rate,
    serviceBill,
    IS_TEST_ENV,
    setManualAddress,
    updatedHousehold,
    quoteLead,
  };
  return (
    <>
      <Formik
        enableReinitialize={quoteLead?.bills?.length > 0}
        innerRef={el => (formikRef = el)}
        validateOnBlur
        validateOnChange={false}
        initialValues={{
          billSelected: [],

          reference: 'BILLING1',
          household_type: quoteLead?.type,
          move_in_start: true,
          tenancy_move_in: '',
          tenancy_start:
            quoteLead?.tenancy_start?.length > 0
              ? new Date(quoteLead?.tenancy_start)
              : '',
          tenancy_end:
            quoteLead?.tenancy_end?.length > 0
              ? new Date(quoteLead?.tenancy_end)
              : '',
          tenancy_type: '',
          tenancy_length: '',
          tenants: [],
          confirm_contract: true,
          agree_pricing: true,
          data_permission: true,
          welcome_signup_email: true,
          perks: [],
          energyBills: 0, // number of energy bills from quote
          // new fields for rent users
          letting_landlord: '',
          recipient_account_number: '',
          letting_agent_sort_code: '',
          rent_payment_reference: '',

          // Lead tenant
          first_name: '',
          last_name: '',

          birthday: '',
          university_year: '',
          nationality: 'GB',
          terms_of_service: false,
          quote_lead_id: quoteLead?.id,
          quote_lead_uuid: quoteLead?.uuid,

          university_name: '',
          university_team: '',
          letting_agent: {
            type: 'agent',
            name: '',
            email: '',
            phone_number: '',
          },

          ...config.userParams,

          address: {
            line1:
              quoteLead?.property?.address_line1 || config.userParams.line1,
            line2:
              quoteLead?.property?.address_line2 || config.userParams.line2,
            city: quoteLead?.property?.address_city || config.userParams.city,
            county:
              quoteLead?.property?.address_county || config.userParams.county,
            postcode: quoteLead?.property?.address_postcode || postCode,
            address_id:
              quoteLead?.property?.external_id || config.userParams.address_id,
          },
          total_occupants:
            quoteLead?.housemates || config.userParams?.total_occupants,
          bill_payers: quoteLead?.bill_payers || config.userParams?.bill_payers,
          email: quoteLead?.email_address,
          phone_number: quoteLead?.phone_number,
        }}
        validationSchema={getValidationSchema(currentStep)}
        onSubmit={async (values, { setErrors }) =>
          getSubmitForm(currentStep)(values, setErrors)
        }
      >
        {({ handleSubmit }) => (
          <OnboardingLayout layoutData={layoutData}>
            <form onSubmit={handleSubmit}>
              <Outlet
                context={{
                  ...sharedContext,
                }}
              />
            </form>
          </OnboardingLayout>
        )}
      </Formik>
    </>
  );
}
