import { useEffect, useState } from 'react';
import { calculateDiscountedPriceForServiceCharge } from '../utils';

export const useCalculatePricePerHousemate = (
  bills,
  serviceBill,
  housemates,
  coupon,
  noOfBillPayers
) => {
  const housematesIndex = Number(housemates) - 1;

  const [pricePerHousemate, setPricePerHousemate] = useState(null);
  const [pricePerHousemateWithoutFee, setPricePerHousemateWithoutFee] =
    useState(null);

  useEffect(() => {
    setPricePerHousemateWithoutFee(calculatePriceWithoutFee());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bills]);

  useEffect(() => {
    setPricePerHousemate(calculatePrice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricePerHousemateWithoutFee, coupon]);

  const calculatePriceWithoutFee = () => {
    return bills.length > 0
      ? bills
          .map(x => {
            let priceOfHousemate = Number(
              x?.prices?.[housematesIndex]?.price_per_housemate
            );

            return priceOfHousemate
              ? priceOfHousemate
              : x?.prices?.[0]?.price_per_housemate;
          })
          .reduce(
            (previousService, currentService) =>
              Number(previousService) + Number(currentService),
            0
          )
      : null;
  };

  const calculatePrice = () => {
    const fee = Number(serviceBill?.prices?.[0]?.price_per_housemate || 0);
    const feesPerHouseMate = Number(
      serviceBill?.prices?.[0]?.fee_per_housemate || 0
    );

    const totalFee = coupon
      ? calculateDiscountedPriceForServiceCharge(coupon, fee, feesPerHouseMate)
      : fee;

    return Number(pricePerHousemateWithoutFee) + totalFee;
  };

  return {
    pricePerHousemate: Number(pricePerHousemate),
    pricePerHousemateWithoutFee: Number(pricePerHousemateWithoutFee),
  };
};
