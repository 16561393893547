import React, { useState } from 'react';
import {
  Dialog,
  Button,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  RadioGroup,
  RadioGroupItem,
  Label,
} from 'homebox-ui';
import { useFormikContext, ErrorMessage } from 'formik';
import { MdEdit } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { activeStep } from '../../utils';

export default function ManageHouseHoldModal({ setFetchQuote = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { values, setValues } = useFormikContext();
  const activePage = activeStep(location);

  const canUpdateHouseHold = activePage?.path === '/setup-bill';

  const [householdData, setHouseholdData] = useState({
    total_occupants: values?.total_occupants,
    bill_payers: values?.bill_payers,
    tenants: values?.tenants,
    household_type: values?.household_type,
  });
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {canUpdateHouseHold ? (
        <div
          onClick={() => setIsOpen(true)}
          className='flex cursor-pointer space-x-1'
        >
          <span className='ml-2 text-sm font-semibold underline'>
            {values?.bill_payers} bill payer(s)
          </span>{' '}
          <MdEdit className='h-4 w-4' />{' '}
        </div>
      ) : (
        <span className='ml-1 text-sm'>
          {values?.bill_payers} bill payer(s)
        </span>
      )}

      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Modify Household Information</DialogTitle>
          <DialogDescription>
            Make changes to your household here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className='grid gap-4 py-4'>
          <div className='w-full sm:max-w-lg'>
            <Label htmlFor='total_occupants'>
              How many people will be living in your new home?
            </Label>
            <RadioGroup
              id='total_occupants'
              name='total_occupants'
              defaultValue={`${householdData?.total_occupants}`}
              value={`${householdData?.total_occupants}`}
              onValueChange={value => {
                setHouseholdData(prevState => {
                  return {
                    ...prevState,
                    total_occupants: Number(value),
                    bill_payers: Number(value),
                    tenants: Array(Number(value)).fill({
                      first_name: '',
                      last_name: '',
                      phone_number: '',
                    }),
                  };
                });
              }}
              className='mt-4 flex flex-wrap'
            >
              {new Array(12).fill('').map((_, idx) => (
                <Label
                  htmlFor={`${idx + 1}-housemates`}
                  key={`${idx + 1}-housemates`}
                  className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark  [&:has([data-state=checked])]:hover:bg-primary-dark mr-[10px] flex h-10 w-10 flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus:ring-4 focus-visible:outline-none focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
                >
                  <RadioGroupItem
                    value={`${idx + 1}`}
                    id={`${idx + 1}-housemates`}
                    className='sr-only'
                  />
                  {`${idx + 1}`}
                  {idx + 1 === 12 && '+'}
                </Label>
              ))}
            </RadioGroup>
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='total_occupants'
            />
          </div>
          {householdData?.total_occupants > 1 && (
            <div className='w-full sm:max-w-lg'>
              <Label htmlFor='bill_payers'>
                How many of these housemates will be paying the bills?
              </Label>
              <RadioGroup
                className='mt-4 flex flex-wrap'
                id='bill_payers'
                name='bill_payers'
                defaultValue={`${householdData.bill_payers}`}
                value={`${householdData.bill_payers}`}
                onValueChange={value => {
                  setHouseholdData(prevState => {
                    return {
                      ...prevState,
                      bill_payers: Number(value),
                      tenants: Array(Number(value)).fill({
                        first_name: '',
                        last_name: '',
                        phone_number: '',
                      }),
                    };
                  });
                }}
              >
                {new Array(householdData.total_occupants)
                  .fill('')
                  .map((_, idx) => (
                    <Label
                      htmlFor={`${idx + 1}-billpayers`}
                      key={`${idx + 1}-billpayers`}
                      className='hover:bg-button-primary-hover ring-primary-lighter border-neutral bg-background-secondary [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark [&:has([data-state=checked])]:hover:bg-primary-dark mr-[10px]  flex h-10 w-10 flex-col items-center justify-center rounded-full border p-2 font-sans text-sm font-medium text-neutral-600 hover:bg-neutral-200 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100'
                    >
                      <RadioGroupItem
                        value={`${idx + 1}`}
                        id={`${idx + 1}-billpayers`}
                        className='sr-only'
                      />
                      {`${idx + 1}`}
                      {idx + 1 === 12 && '+'}
                    </Label>
                  ))}
              </RadioGroup>
              <ErrorMessage
                component='span'
                className='text-error text-xs'
                name='bill_payers'
              />
            </div>
          )}
          <div>
            <Label className='font-sans'>What type of tenant are you?</Label>
            <RadioGroup
              className='mt-2 flex w-full space-x-2 sm:max-w-md'
              defaultValue={householdData.household_type}
              name='household_type'
              value={householdData.household_type}
              onValueChange={value => {
                setHouseholdData({
                  ...householdData,
                  household_type: value,
                });
              }}
            >
              <Label
                htmlFor='professional-tenant'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='professional-tenant'
                  id='professional-tenant'
                  className='sr-only'
                />
                Professional
              </Label>
              <Label
                htmlFor='student'
                className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark flex h-10 w-full flex-col items-center justify-center rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
              >
                <RadioGroupItem
                  value='student'
                  id='student'
                  className='sr-only'
                />
                Student
              </Label>
            </RadioGroup>
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='household_type'
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              setValues(prevValues => ({
                ...prevValues,
                ...householdData,
              }));
              setFetchQuote(`${Math.random() * (99999 - 1) + 1}`);
              setIsOpen(false);
            }}
            type='button'
          >
            Save changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
